<!--
 * @Description: 权限策略
 * @Author: 琢磨先生
 * @Date: 2022-05-19 11:14:25
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-15 09:45:46
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit" round icon="plus">新增</el-button>
  </el-card>
  <el-card class="box data">
    <el-tabs @tab-change="tabChange">
      <el-tab-pane :label="item.name" v-for="item in app_group_list" :key="item.code"></el-tab-pane>
    </el-tabs>
    <el-table :data="tableData" v-model="loading" row-key="id" border>
      <el-table-column label="名称" prop="name" width></el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)">修改</el-button>
          <el-button type="success" link size="small" @click="onCopy(scope.row)">复制</el-button>
          <el-popconfirm title="您确定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-power :item="current" :group="app_group_list" :copy="is_copy" @success="loadData" @close="onClosed"></edit-power>
</template>

<script>
import app_api from "@/http/app_api";
import EditPower from "./edit_power.vue";

export default {
  components: {
    EditPower,
  },
  data() {
    return {
      loading: true,
      activeName: "",
      // apps: [],
      app_group_list: [],
      tableData: [],
      current: null,
      //复制？
      is_copy:false,
    };
  },
  created() {
    /**
     * 加载全部的应用
     */
    app_api.get_apps().then((res) => {
      if (res.code == 0) {
        this.apps = res.data;
        if (res.data.length > 0) {
          var first = res.data[0];
          this.activeName = first.app_key;
          this.app_key = first.app_key;
          // this.loadData();
        }
      }
    });

    /**
     *
     */
    app_api.get_group().then((res) => {
      if (res.code == 0) {
        this.app_group_list = res.data;
        if (res.data && res.data.length > 0) {
          this.code = res.data[0].code;
          this.loadData();
        }
      }
    });
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      // this.current = null;
      // this.is_copy = false;
      this.loading = true;

      this.$http.get(`admin/v1/power/by_code?code=${this.code}`).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },

    /**
     * 复制
     * @param {*} item
     */
    onCopy(item) {
      this.is_copy = true;
      this.current = item;
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("/admin/v1/power/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },

    onClosed(){
      this.current = null;
      this.is_copy = false;
    },
    /**
     * tab切换
     */
    tabChange(val) {
      this.code = val;
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>