<!--
 * @Description: 新增、修改策略
 * @Author: 琢磨先生
 * @Date: 2022-05-19 11:30:12
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-11-04 10:21:17
-->

<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    :close-on-click-modal="false"
    size="1000px"
    @closed="onClosed"
    custom-class="custom"
  >
    <div v-loading="loading" class="flex-grow flex-column" style="height: 1px;">
      <el-form :model="form" ref="form" :rules="rules" label-width="140px">
        <el-form-item label="应用" prop="app_code">
          <el-select
            v-model="form.app_code"
            placeholder
            @change="appChange"
            :disabled="form.id > 0"
          >
            <el-option v-for="item in group" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="父级" v-if="powers.length > 0">
          <el-cascader
            v-model="form.powerIds"
            :options="powers"
            :props="{
            label: 'name',
            value: 'id',
            checkStrictly: true,
          }"
            clearable
            :disabled="form.id > 0"
          />
        </el-form-item>

        <el-form-item label="名称" prop="name">
          <el-col :span="10">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <!-- <el-divider content-position="left">选择权限数据</el-divider> -->
      <!-- <el-tabs>
      <el-tab-pane name="0" label="菜单">
        <el-table
          ref="tbMenu"
          height="100%"
          :data="functions.filter((x) => x.is_menu)"
          row-key="id"
          border
          @selection-change="menuSelectionChange"
        >
          <el-table-column type="selection" width="55" reserve-selection  />
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="url地址" prop="url"></el-table-column>
          <el-table-column label="序号" prop="sequence"></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane name="1" label="接口">
        <el-table
          ref="tbApi"
          height="100%"
          :data="functions.filter((x) => !x.is_menu)"
          row-key="id"
          border
          default-expand-all
          @selection-change="apiSelectionChange"
        >
          <el-table-column type="selection" width="55" reserve-selection />
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="url地址" prop="url"></el-table-column>
          <el-table-column label="序号" prop="sequence"></el-table-column>
        </el-table>
      </el-tab-pane>
      </el-tabs>-->
      <el-tabs v-model="activeName">
        <el-tab-pane
          :name="item.app_key"
          :label="item.name"
          v-for="item in apps"
          :key="item.app_key"
        >
          <el-table
            :ref="(el)=>item.refTable =el"
            height="100%"
            :data="item.functions"
            row-key="id"
            border default-expand-all
            @selection-change="(values)=>{item.selection_list = values;}"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="url地址" prop="url"></el-table-column>
            <el-table-column label="序号" prop="sequence"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <!-- <div class="flex-grow" style="height: 1px;">

      </div>-->
    </div>
    <template #footer>
      <div>
        <el-button type="primary" @click="onSubmit" :loading="saving" :disabled="saving">确定</el-button>
        <!-- <el-button @click="cancelClick" :disabled="saving">取消</el-button> -->
      </div>
    </template>
  </el-drawer>
</template>

<script>
import app_api from "@/http/app_api";
export default {
  data() {
    return {
      drawer: false,
      loading: false,
      saving: false,
      activeName: "",
      title: "新增策略",
      //应用端
      apps: [],
      //选择父级下拉
      powers: [],
      form: {},
      rules: {
        app_key: [
          {
            required: true,
            message: "请选择应用",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
      //当前选中的应用
      current_app: {},
    };
  },
  props: ["item", "group", "copy"],
  emits: ["close", "success"],
  created() {},
  watch: {
    item: {
      handler() {
        this.form = {};
        if (this.item) {
          this.apps = [];
          this.powers = [];
          this.functions = [];
          this.drawer = true;
          this.dtl = null;
          this.form = {
            functionIds: [],
          };
          if (this.item.id) {
            this.form = Object.assign({}, this.item);
            this.loadDtl();
            this.title = this.copy ? "新增" : "修改";
          } else {
            this.title = "新增";
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * app选择更改
     */
    appChange(key) {
      this.code = key;
      this.loadApp();
      this.loadPowers();
    },

    // /**
    //  * 应用端选择更改
    //  * @param {*} val
    //  */
    // platformChange(val) {
    //   this.current_app = this.apps.find((o) => o.app_key == val);
    // },

    /**
     * 获取详情
     */
    loadDtl() {
      this.$http.get("admin/v1/power/dtl?id=" + this.form.id).then((res) => {
        if (res.code == 0) {
          this.dtl = res.data;
          this.form.powerIds = this.dtl.fatherIds;
          this.form.functionIds = this.dtl.functionIds;
          this.code = this.dtl.app_code;
          // console.log(this.form);
          if (this.copy) {
            delete this.form.id;
            this.loadPowers();
          }
          this.loadApp();
        }
      });
    },

    /**
     * 根据应用组code 加载app列表
     */
    loadApp() {
      app_api.get_by_code(this.code).then((res) => {
        if (res.code == 0) {
          this.apps = res.data;
          if (this.apps.length > 0) {
            this.activeName = this.apps[0].app_key;
            this.apps.forEach((item) => {
              //选中的数据列表
              item.selection_list = [];
              //首次加载
              item.is_first = true;
              this.loadFunction(item);
            });
            // this.platformChange(this.activeName);
          }
        }
      });
    },

    /**
     * 获取菜单、API数据
     */
    loadFunction(item) {
      item.loading = true;
      this.$http
        .get("admin/v1/function/tree?appkey=" + item.app_key)
        .then((res) => {
          if (res.code == 0) {
            item.functions = res.data;
            if (this.dtl) {
              //加载了详情
              this.$nextTick(() => {
                item.functions.forEach((x) => {
                  this.defaultCheck(x, item);
                });
              });
            }
          }
          item.loading = false;
        });
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.powerIds && this.form.powerIds.length > 0) {
            this.form.father_id =
              this.form.powerIds[this.form.powerIds.length - 1];
          }
          var functionIds = [];
          this.apps.forEach((app) => {
            app.selection_list.forEach((item) => {
              functionIds.push(item.id);
            });
          });
          var model = {
            id: this.form.id,
            app_code: this.form.app_code,
            functionIds: functionIds,
            name: this.form.name,
            father_id: this.form.father_id,
          };
          console.log(model);
          this.saving = true;

          this.$http
            .post("/admin/v1/power/edit", model)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },

    onClosed() {
      this.$emit("close");
    },

    // /**
    //  * 选择的功能数据
    //  */
    // tableSelectionChange(values, app) {
    //   console.log(app);
    //   app.selection_list = values;
    // },
    // /**
    //  * 选择的功能数据
    //  */
    // apiSelectionChange(values) {
    //   this.selected_apis = values;
    // },
    /**
     * 加载数据
     */
    loadPowers() {
      this.loading = true;
      this.$http.get(`admin/v1/power/by_code?code=${this.code}`).then((res) => {
        if (res.code == 0) {
          this.powers = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 修改时默认选中
     */
    defaultCheck(item, app) {
      if (this.dtl.functionIds.indexOf(item.id) > -1) {
        //存在
        app.refTable.toggleRowSelection(item, true);
      }
      if (item.children.length > 0) {
        item.children.forEach((child) => {
          this.defaultCheck(child, app);
        });
      }
    },
  },
};
</script>

<style >
.custom .el-drawer__body {
  display: flex !important;
  flex-direction: column !important;
}
.custom .el-tabs {
  height: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.custom .el-tabs__content {
  flex-grow: 1;
  height: 1px;
}

.custom .el-tab-pane {
  height: 100%;
}

.custom .el-table {
  height: 100% !important;
}
</style>
